<template>
  <div class="container">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>

    <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          v-show="index!==4 || isAssign"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        > 
          <div>
            <el-badge :value="backlog" class="item" v-if="nav.name === '待办管理' && backlog != '0'">
              <i v-if="nav.name === '待办管理'" class="icon icon-await"></i>
            </el-badge>
            <i v-if="nav.name === '待办管理' && backlog == '0'" class="icon icon-await"></i>
            <i v-if="nav.name === '办文管理'" class="icon icon-processing"></i>
            <i v-if="nav.name === '拟文管理'" class="icon icon-draft"></i>
            <i v-if="nav.name === '全部公文'" class="icon icon-all"></i>
            <i v-if="nav.name === '指派管理'" class="icon icon-zp"></i>
            <span>{{nav.name}}</span>
           </div>
        </router-link>
      </div>
      <div class="nf-tabs-content">
        <router-view />
      </div>
    </div>
    <el-dialog
      title="认证信息"
      :visible.sync="centerDialogVisible"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      center
    >
      <span>你还没有进行账户认证</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="centerDialogVisibles()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="权限"
      :visible.sync="centerDialogVisiblese"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      center
    >
      <span>您还没有进行公文管理的权限</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="centerDialogVisibles()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";

export default {
  components: {
    nfBreadcrumb
  },

  data() {
    return {
      centerDialogVisible: false,
      centerDialogVisiblese: false,
      activeNav: 0,
      tabsNav: [
        {
          name: "待办管理",
          url: "awaitDocumentText"
        },
        {
          name: "办文管理",
          url: "documentText"
        },
        {
          name: "拟文管理",
          url: "proposedText"
        },
        {
          name: "全部公文",
          url: "allOfficial"
        },
        {
          name: "指派管理",
          url: "assign"
        }
      ],
      breadList: [
        {
          name: "业务协同"
        },
        {
          name: "移动办公"
        }
      ],
      backlog: '', // 待办管理数量
      param: {
        circulateType: 2,
        documentStatus: 2,
        emergencyLevel: '',
        endDate: '',
        keyWord: '',
        pageNum: 1,
        pageSize: 10,
        startDate: ''
      },
      isAssign: false
    };
  },

  created() {
    const path = this.$route.path;
    this.queryReceiptList();
    if (path === "/awaitDocumentText") {
      this.activeNav = 0;
    } else if (path === "/documentText") {
      this.activeNav = 1;
    } else if (path === "/proposedText") {
      this.activeNav = 2;
    } else if (path === "/allOfficial") {
      this.activeNav = 3;
    } else if (path === "/assign") {
      this.activeNav = 4;
    }
    this.getIsAdmin();
  },

  methods: {
    centerDialogVisibles() {
       this.$router.push("/index");
    },
    async getIsAdmin () {
      const api = this.$fetchApi.isAdministrators.api;
      const data = await this.$fetchData.fetchGet({}, api, 'json');
      if (data.code == '200') {
        this.isAssign =   data.result;
      }
    },
    async queryUserRank() {
      const api = this.$fetchApi.queryUserRank.api;
      const data = await this.$fetchData.fetchPost({}, api, "json");
      if (data.code === "200") {
        if (data.result.type == 0) {
          this.centerDialogVisible = true;
        } else {
          if (data.result.rank == 0) {
            this.centerDialogVisiblese = true;
          } else {
            return;
          }
        }
      }
    },
    async queryReceiptList() {
      const api = this.$fetchApi.queryReceiptList.api;
      const data = await this.$fetchData.fetchPost(this.param, api, 'json');
      if (data.code === '200') {
        this.backlog = data.result.total;
        this.$store.commit('render', this.backlog);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-await {
      background-image: url("../../assets/images/officialDocument/icon_db.png");
    }

    &-processing {
      background-image: url("../../assets/images/officialDocument/icon_bw.png");
    }

    &-draft {
      background-image: url("../../assets/images/officialDocument/icon_nw.png");
    }
    &-all {
      background-image: url("../../assets/images/officialDocument/icon_all.png");
    }
    &-zp {
      background-image: url("../../assets/images/officialDocument/icon_zp.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      padding: 0 48px 0 24px;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
